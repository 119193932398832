var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", [_vm._v("Informationen")]),
                      _c("CCardBody", [
                        _c("dl", [
                          _c("dt", [_vm._v("Id")]),
                          _c("dd", [_vm._v(_vm._s(_vm.customer.id))]),
                          _c("dt", [_vm._v("Name")]),
                          _c("dd", [
                            _vm._v(
                              _vm._s(_vm.customer.displayName) +
                                " (" +
                                _vm._s(_vm.customer.firstName) +
                                " " +
                                _vm._s(_vm.customer.lastName) +
                                ")"
                            )
                          ]),
                          _c(
                            "dt",
                            [
                              _vm._v(" Email "),
                              _c(
                                "CBadge",
                                {
                                  attrs: {
                                    color: _vm.customer.emailConfirmed
                                      ? "secondary "
                                      : "danger"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.customer.emailConfirmed
                                          ? "Bestätigt"
                                          : "Nicht bestätigt"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("dd", [
                            _vm._v(" " + _vm._s(_vm.customer.email) + " ")
                          ]),
                          _c("dt", [_vm._v("Username")]),
                          _c("dd", [_vm._v(_vm._s(_vm.customer.userName))]),
                          _c("dt", [_vm._v("Letzter Login")]),
                          _c("dd", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.customer.lastLoginAt.toLocaleString()
                                ) +
                                " (" +
                                _vm._s(
                                  _vm.formatDistance(_vm.customer.lastLoginAt)
                                ) +
                                ") "
                            )
                          ]),
                          _c("dt", [_vm._v("Registrierung")]),
                          _c("dd", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.customer.createdAt.toLocaleString()
                                ) +
                                " (" +
                                _vm._s(
                                  _vm.formatDistance(_vm.customer.createdAt)
                                ) +
                                ") "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", [_vm._v("Umsätze")]),
                      _c("CCardBody", [
                        _c("dl", [
                          _c("dt", [_vm._v("Buchungen")]),
                          _c("dd", [_vm._v(_vm._s(_vm.customer.countOrders))]),
                          _c("dt", [_vm._v("Umsatz")]),
                          _c("dd", [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(_vm.customer.sumOrderAmounts)
                              )
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  !_vm.isProduction
                    ? _c(
                        "CCard",
                        [
                          _c("CCardHeader", [_vm._v("Testing")]),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CButton",
                                {
                                  attrs: { color: "secondary" },
                                  on: { click: _vm.migrateToGutshof }
                                },
                                [_vm._v("Convert Complete User to Gutshof")]
                              ),
                              _c("br"),
                              _c("br"),
                              _c(
                                "CButton",
                                {
                                  attrs: { color: "secondary" },
                                  on: { click: _vm.migrateOrdersToGutshof }
                                },
                                [_vm._v("Convert Only Orders to Gutshof")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }